import React from "react";
import standing1 from "../../assets/standing-1.png";
import standing2 from "../../assets/standing-2.png";
import standing13 from "../../assets/standing-13.png";
import standing14 from "../../assets/standing-14.png";
import standing15 from "../../assets/standing-15.png";
import standing18 from "../../assets/standing-18.png";
import standing24 from "../../assets/standing-24.png";
import standing5 from "../../assets/standing-5.png";

const LandingPage = () => {
  return (
    <>
      <section
        class="hero is-info is-halfheight"
        style={{ backgroundColor: "grey" }}
      >
        <div
          class="hero-body"
          style={{
            display: "flex",
            backgroundColor: "#577590",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "#577590",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", width: "100%", zIndex: 1 }}>
              <img src={standing1} alt="" style={{ maxWidth: "15vw" }} />
            </div>
            <p
              class="title"
              style={{
                position: "absolute",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "12vw",
              }}
            >
              CHURCHLINGO
            </p>
            <div style={{ display: "flex", zIndex: 1 }}>
              <img
                src={standing2}
                alt=""
                style={{
                  maxWidth: "15vw",
                  transform: "scaleX(-1)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section class="section is-medium">
        <div class="container">
          <div class="columns">
            <div class="column is-half">
              <h1 class="title" style={{ color: "#577590" }}>
                Real-Time Transcription & Translation for Churches{" "}
              </h1>
              <h2 class="subtitle" style={{ fontFamily: "Instrument Sans" }}>
                Break down language barriers in your church with Churchlingo.
                Our service provides real-time transcription and translation of
                sermons, prayers, and worship, allowing everyone to connect with
                the message in their preferred language.
              </h2>
            </div>
            <div class="column is-half">
              <div className="content is-small">
                <h1 class="title" style={{ color: "#577590" }}>
                  Key Features
                </h1>
                <ul>
                  <li>
                    <h2
                      class="subtitle"
                      style={{ fontFamily: "Instrument Sans" }}
                    >
                      <b>Instant Transcription & Translation</b>: Capture spoken
                      words and translate them into multiple languages
                      instantly.
                    </h2>
                  </li>
                  <li>
                    <h2
                      class="subtitle"
                      style={{ fontFamily: "Instrument Sans" }}
                    >
                      <b>Inclusive Worship:</b> Support non-native speakers and
                      the hearing impaired with live transcriptions and
                      translations on screens or personal devices.
                    </h2>
                  </li>
                  <li>
                    <h2
                      class="subtitle"
                      style={{ fontFamily: "Instrument Sans" }}
                    >
                      <b>Easy Integration:</b> Seamlessly connects with your
                      existing audio and visual systems.
                    </h2>
                  </li>
                  <li>
                    <h2
                      class="subtitle"
                      style={{ fontFamily: "Instrument Sans" }}
                    >
                      <b>Broad Language Support:</b> Choose from a variety of
                      languages to meet the needs of your diverse congregation.
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium">
        <div
          style={{ display: "flex", width: "100%", alignItems: "space-evenly" }}
        >
          <img src={standing13} alt="" style={{ maxWidth: "16.6%" }} />
          <img src={standing14} alt="" style={{ maxWidth: "16.6%" }} />
          <img src={standing15} alt="" style={{ maxWidth: "16.6%" }} />
          <img src={standing5} alt="" style={{ maxWidth: "16.6%" }} />
          <img src={standing18} alt="" style={{ maxWidth: "16.6%" }} />
          <img src={standing24} alt="" style={{ maxWidth: "16.6%" }} />
        </div>
      </section>
      <section
        className="section is-medium"
        style={{ backgroundColor: "#577590" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="title" style={{ color: "white" }}>
            Join Us!
          </div>
          <div
            className="subtitle"
            style={{ textAlign: "center", color: "white", width: "50%" }}
          >
            Ready to embark on this divine journey with us? Click ‘Translate
            Sermons’ to see our upcoming services. Looking to bring ChurchLingo
            to your church?
          </div>
          <button className="button is-warning">Sign Up</button>a{" "}
        </div>
      </section>
    </>
  );
};

export default LandingPage;
