import React from "react";
import StripePricingTable from "./PricingTable";

import standing24 from "../../assets/standing-24.png";
import standing5 from "../../assets/standing-5.png";
export default function PricingPage() {
  return (
    <>
      <section class="hero is-info" style={{ backgroundColor: "grey" }}>
        <div
          class="hero-body"
          style={{
            display: "flex",
            backgroundColor: "#577590",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "#577590",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", width: "100%", zIndex: 1 }}>
              <img src={standing5} alt="" style={{ maxWidth: "15vw" }} />
            </div>
            <p
              class="title"
              style={{
                position: "absolute",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "3vw",
              }}
            >
              Choose the best plan for your organisation.
            </p>
            <div style={{ display: "flex", zIndex: 1 }}>
              <img
                src={standing24}
                alt=""
                style={{ maxWidth: "15vw", transform: "scaleX(-1)" }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <StripePricingTable />
      </div>
    </>
  );
}
