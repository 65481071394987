import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { socket } from "../../socket";
export default function ViewerLandingPage({ timeoutDuration = 5000 }) {
  const languageOptions = [
    {
      key: "Italien",
      value: "it",
    },
    {
      key: "French",
      value: "fr",
    },
    {
      key: "English",
      value: "en",
    },
  ];

  const [timeoutId, setTimeoutId] = useState(null);
  const [language, setLanguage] = useState("Select a language");
  const [chosenLangauge, setChosenLanguage] = useState();
  const [msg, setMessage] = useState("");
  const params = useParams();
  const [streaming, setStreaming] = useState(false);
  const [clearTextInterval, setClearTextInterval] = useState(5000);
  let lang = useRef(null);
  let total_msg = useRef("");
  const [fontSize, setFontSize] = useState(14);

  useEffect(() => {
    if (clearTextInterval == 0) {
      return;
    }

    // Clear the existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      total_msg.current = "";
    }, clearTextInterval);

    // Save the timeout ID so it can be cleared later
    setTimeoutId(newTimeoutId);

    // Cleanup the timeout on component unmount or when text changes
    return () => clearTimeout(newTimeoutId);
  }, [msg]);

  useEffect(() => {
    const getStreamStatus = async () => {
      const response = await fetch(`/api/stream_status/${params.id}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.result === false) {
        setStreaming(data.result);
      } else if (data.result === true) {
        setStreaming(data.result);
      }
    };
    getStreamStatus();
  }, []);

  useEffect(() => {
    function setTranslationMessage(data) {
      if (typeof data !== "undefined") {
        let val = data["privMap"]["privKeys"].indexOf(lang.current);

        total_msg.current =
          total_msg.current + data["privMap"]["privValues"][val];
        setMessage(total_msg.current);
        var textarea = document.getElementById("textarea_id");
        textarea.scrollTop = textarea.scrollHeight;
      }
    }

    socket.on("translated", (data) => {
      setTranslationMessage(data);
    });
  }, [socket]);

  function handleClick() {
    setChosenLanguage(language);
    lang.current = language;
    socket.emit("join", { room: params.id, language: language });
  }

  function onChangeLanguage() {
    setMessage("");
    total_msg.current = "";
    setChosenLanguage(null);
    socket.emit("leave", { room: params.id });
  }
  if (!streaming) {
    return (
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        <section className="section is-large">
          <div className="columns is-multiline is-mobile is-centered">
            <p> Stream has not started yet</p>
          </div>
        </section>
      </div>
    );
  }
  return (
    <>
      {chosenLangauge ? (
        <>
          <section className="section">
            <div className="container  has-text-centered">
              <div className="columns is-centered is-multiline">
                <div className="column is-narrow is-flex-direction-row mt-5">
                  <button
                    className="button is-primary is-rounded"
                    onClick={() => setFontSize(fontSize + 1)}
                  >
                    <p style={{ fontFamily: "Instrument Sans" }}>
                      <strong>Font Size +</strong>
                    </p>
                  </button>
                  <button
                    className="button is-primary is-rounded ml-1"
                    onClick={() => setFontSize(fontSize - 1)}
                  >
                    <p style={{ fontFamily: "Instrument Sans" }}>
                      <strong>Font Size -</strong>
                    </p>
                  </button>
                </div>

                <div className="column is-narrow">
                  <div class="control has-icons-right">
                    <label style={{ fontFamily: "Instrument Sans" }}>
                      Interval before clearing text:
                    </label>

                    <output for="sliderWithValue">
                      {clearTextInterval == 0 ? (
                        <>
                          {" "}
                          <p style={{ fontFamily: "Instrument Sans" }}>
                            {" "}
                            No clear
                          </p>
                        </>
                      ) : (
                        <>
                          <p style={{ fontFamily: "Instrument Sans" }}>
                            {" "}
                            {clearTextInterval / 1000} seconds
                          </p>
                        </>
                      )}
                    </output>
                  </div>
                  <input
                    id="sliderWithValue"
                    class="slider is-fullwidth"
                    step="1"
                    min="0"
                    max="100"
                    value={clearTextInterval / 1000}
                    onChange={(e) =>
                      setClearTextInterval(e.target.value * 1000)
                    }
                    type="range"
                  />
                </div>
                <div className="column is-three-quarters">
                  <div className="section">
                    <textarea
                      id="textarea_id"
                      style={{
                        fontSize: `${fontSize}px`,
                        minHeight: "50vh",
                      }}
                      className="textarea is-large"
                      value={msg}
                      readOnly
                      onChange={() => {
                        var textarea = document.getElementById("textarea_id");
                        textarea.scrollTop = textarea.scrollHeight;
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="column has-text-centered is-half">
                  <button class="button is-warning" onClick={onChangeLanguage}>
                    <strong>Change Language</strong>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div
          style={{
            height: "100vh",
          }}
        >
          <section className="section is-large">
            <div className="columns is-multiline is-mobile is-centered">
              <div class="select is-primary">
                <select onChange={(e) => setLanguage(e.target.value)}>
                  <option value="Select a language">Select a language</option>
                  {languageOptions.map((option) => (
                    <option value={option.value}>{option.key}</option>
                  ))}
                </select>
              </div>
              <div
                className="column is-full"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="buttons">
                  <button
                    className="button is-primary is-rounded is-focused"
                    onClick={handleClick}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
